<template>
  <div class="sell-offer--wrap">
    <div v-if="isLoading" class="d-flex justify-center py-15">
      <v-progress-circular indeterminate color="accent" />
    </div>

    <v-form
      v-else-if="stockAmount || toEdit"
      ref="form"
      v-model="formIsValid"
      @submit.prevent="onSubmit"
    >
      <v-card>
        <v-card-title>
          <v-chip label color="accent" class="mr-4 my-1">
            {{ $t('sell.availableStocks') }}&nbsp;<strong>{{ stockAmount | localeNumber($i18n.locale) }}</strong>
          </v-chip>
          <v-chip
            v-if="onHold"
            label
            color="accent"
            class="mr-4 my-1"
          >
            {{ $t('sell.stocksOnHold') }}&nbsp;<strong>{{ onHold | localeNumber($i18n.locale) }}</strong>
          </v-chip>
          <v-chip v-if="!isSelloutUser" label color="accent" class="mr-4 my-1">
            {{ $t('sell.minStocks') }}&nbsp;<strong>{{ minimumShares | localeNumber($i18n.locale) }}</strong>
          </v-chip>
          <v-chip label color="accent" class="mr-4 my-1">
            {{ $t('sell.companyRatio') }}&nbsp;<strong>{{ companyRatio*100 }}&nbsp;%</strong>
          </v-chip>
          <v-chip label color="accent" class="my-1">
            {{ $t('sell.formulaPrice') }}&nbsp;<strong>{{ formulaPrice | readablePrice($i18n.locale) }}</strong>
          </v-chip>
        </v-card-title>

        <v-divider />

        <v-card-text class="py-8">
          <v-row>
            <v-col :cols="12" :md="4">
              <price-input
                v-model="offer.price"
                :label="$t('sell.stockPrice')"
              />
            </v-col>
            <v-col :cols="12" :md="4">
              <v-text-field
                v-model.number="offer.amount"
                prepend-inner-icon="mdi-counter"
                type="number"
                :min="minimumAmount"
                :max="allowedAmountToSell"
                :rules="[...requiredRules, v => v >= minimumAmount]"
                :label="$t('sell.stockAmount')"
                hide-details
                outlined
                class="pa-0"
              />
            </v-col>
            <v-col :cols="12" :md="4">
              <v-slider
                v-model="offer.amount"
                thumb-label
                hide-details
                :max="allowedAmountToSell"
                class="my-3"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="12" :md="4">
              <date-text-input
                :value="offer.expires | isoDate"
                :label="$t('sell.activeUntil')"
                :allowed-dates="allowedDates"
                :rules="requiredRules"
                @input="isoDate => offer.expires = new Date(isoDate)"
              />
            </v-col>
            <v-col :cols="12" :md="4">
              <time-text-input
                :value="offer.expires"
                :label="$t('sell.activeUntil')"
                @input="date => offer.expires = date"
              />
            </v-col>
          </v-row>

          <v-switch
            v-model="offer.boundToConfiguration"
            hide-details
            inset
            class="mt-8"
          >
            <template #label>
              {{ $t('sell.boundToConfiguration') }}
            </template>
          </v-switch>

          <v-switch
            :input-value="!offer.anonymous"
            hide-details
            inset
            @change="active => offer.anonymous = !active"
          >
            <template #label>
              {{ $t('sell.createNotAnonymous') }}
            </template>
          </v-switch>

          <v-switch
            v-model="readTerms"
            :rules="requiredRules"
            hide-details
            inset
          >
            <template #label>
              <accepted-terms-label />
            </template>
          </v-switch>

          <v-alert
            v-if="offer.amount > stockAmount - onHold"
            color="deep-orange"
            class="mt-8 white--text"
            icon="mdi-alert"
          >
            {{ $t('salesRequests.onHoldWarning') }}
          </v-alert>
        </v-card-text>

        <v-divider />

        <v-card-actions class="px-4 d-flex align-center">
          <v-spacer />

          <v-btn
            :disabled="!formIsValid"
            depressed
            color="primary"
            type="submit"
          >
            <v-icon left>
              mdi-offer
            </v-icon>
            {{ toEdit ? $t('sell.updateOffer') : $t('sell.createOffer') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <revenue-preview
        v-if="offer.amount >= minimumAmount && offer.price"
        :amount-to-sell="offer.amount"
        :stock-price="offer.price"
        class="mt-6"
      />
    </v-form>

    <v-alert v-else color="secondary" dark icon="mdi-information" class="d-inline-block mb-0">
      {{ $t('sell.noOfferAllowed') }}
    </v-alert>

    <confirm-dialog
      :headline="toEdit ? $t('sell.updateOffer') : $t('sell.createOffer')"
      info-text=""
      :action-text="$t('common.confirm')"
      action-icon="mdi-check"
      action-color="primary"
      :is-visible="showConfirmation"
      @cancel="showConfirmation = false"
      @ok="saveOffer"
    >
      <offer-summary :amount="offer.amount" :price="offer.price" />

      <v-form @submit.prevent="saveOffer">
        <v-text-field
          v-model="offer.secondFactorCode"
          prepend-inner-icon="mdi-key"
          :label="$t('salesRequests.twoFactorInfo')"
          hide-details
          outlined
          autofocus
          class="pa-0 mt-6 mb-2"
        />
      </v-form>
    </confirm-dialog>
  </div>
</template>

<script>
import { add } from 'date-fns'
import roles from '@/roles'

import AcceptedTermsLabel from '@/components/AcceptedTermsLabel'
import ConfirmDialog from '@/components/ConfirmDialog'
import DateTextInput from '@/components/DateTextInput'
import OfferApi from '@/api/Offer'
import OfferSummary from './OfferSummary'
import PriceInput from '@/components/PriceInput'
import RevenuePreview from '@/views/Stocks/UserOffers/RevenuePreview'
import ShareApi from '@/api/Share'
import TimeTextInput from '@/components/TimeTextInput'

const defaultOffer = () => ({
  amount: 0,
  anonymous: true,
  boundToConfiguration: true,
  expires: add(new Date(), { days: 1 }),
  price: null,
  offerType: 'sell',
  secondFactorCode: '',
})

export default {
  name: 'sell-offer',

  components: {
    AcceptedTermsLabel,
    ConfirmDialog,
    DateTextInput,
    OfferSummary,
    PriceInput,
    RevenuePreview,
    TimeTextInput,
  },

  props: {
    toEdit: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      formIsValid: true,
      isLoading: false,
      readTerms: false,
      showConfirmation: false,
      stockAmount: 0,
      maxPrice: 1000000,
      offer: defaultOffer(),
      onHold: null,
    }
  },

  computed: {
    allowedAmountToSell () {
      return this.toEdit ? this.toEdit.amount + this.stockAmount : this.stockAmount
    },

    companyRatio () {
      return this.$store.getters.companyRatio
    },

    formulaPrice () {
      return this.$store.state.configuration.formulaPrice
    },

    // if the holding gets shares 2 is the minimum - otherwise separation is not possible
    minimumAmount () {
      return this.companyRatio > 0 ? 2 : 0
    },

    // the api specifies a minimum - but users with the sellout-rule are allowed to ignore that
    minimumShares () {
      return this.isSelloutUser ? 0 : this.$store.state.configuration.minimumShares
    },

    // a price must be positive, below the maximum and can have up to two decimal places
    priceRules () {
      return [
        ...this.requiredRules,
        v => v >= 0,
        v => v <= this.maxPrice,
        v => {
          const decimalSplit = v.toString().split('.')
          return !(decimalSplit.length > 1 && decimalSplit[1].length > 2)
        },
      ]
    },

    requiredRules () {
      return [v => !!v || this.$t('common.requiredField')]
    },

    // user is allowed to sell all of his shares, there's no limit
    isSelloutUser () {
      return this.$store.state.user.user.roles.includes(roles.sellout)
    },
  },

  watch: {
    toEdit () {
      this.setOffer()
    }
  },

  mounted () {
    this.getAvailableShares()
    this.setOffer()
  },

  methods: {
    /**
     * Users should only be able to select future dates.
     *
     * @param {string} date iso-date without time
     * @returns {boolean}
     */
    allowedDates (date) {
      return new Date(date) > new Date()
    },

    /**
     * Creates/updates an offer, resets the state, redirects the user to his
     * offer-overview if everything went fine, a new one was created (when
     * updating, we're already there).
     *
     * @returns {void}
     */
    async saveOffer () {
      this.showConfirmation = false

      const res = this.toEdit
        ? await OfferApi.update(this.offer)
        : await OfferApi.create(this.offer)

      if (!res.ok) {
        this.offer.secondFactorCode = ''

        return this.$store.commit('setSnackbar', {
          text: OfferApi.isSecondFactorError(res) ? this.$t('login.twoFactorError') : this.$t('common.errorOccured'),
          color: 'error',
        })
      }

      this.readTerms = false
      this.offer = defaultOffer()
      this.$refs.form.resetValidation()

      if (this.toEdit) {
        this.$emit('updated:offer')
        return this.$store.commit('setSnackbar', { text: this.$t('common.updated') })
      }

      this.$store.commit('setSnackbar', { text: this.$t('common.created') })
      this.$router.push({ name: 'UserOffers' })
    },

    /**
     * Opens the confirmation-dialog if the entered data is valid.
     *
     * @returns {void}
     */
    onSubmit () {
      this.$refs.form.validate()

      this.formIsValid
        ? (this.showConfirmation = true)
        : this.$store.commit('setSnackbar', { text: this.$t('common.pleaseCorrect'), color: 'error' })
    },

    /**
     * Loads the amount of shares a user is allowed to trade.
     *
     * @returns {void}
     */
    async getAvailableShares () {
      this.isLoading = true
      const res = await ShareApi.getAvailableShares()

      if (res.ok) {
        const { amount, onHold } = await res.json()

        this.stockAmount = amount
        this.onHold = onHold
      }

      this.isLoading = false
    },

    /**
     * Uses the offer given as a prop instead of the empty default so the user
     * is able to edit the dataset.
     *
     * @returns {void}
     */
    setOffer () {
      if (this.toEdit !== null) {
        this.offer = {
          ...this.toEdit,
          expires: new Date(this.toEdit.expires),
          secondFactorCode: '',
        }
      }
    },
  },
}
</script>
