var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transaction-table--wrap"},[_c('data-table',{attrs:{"columns":_vm.columns,"custom-page-size":25,"fallback-sorting":"id","items":_vm.filteredTransactions,"page-sizes":[10, 25, 50, 100],"default-options":{ sortBy: ['datePerformed'], sortDesc: [true] },"title":_vm.title,"is-loading":_vm.isLoading},scopedSlots:_vm._u([(_vm.withActions)?{key:"headerActions",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap align-center"},[_c('v-autocomplete',{staticClass:"pa-0",attrs:{"items":_vm.users,"item-text":"name","item-value":"id","dense":"","outlined":"","label":_vm.$t('common.user'),"hide-details":"","clearable":"","prepend-inner-icon":"mdi-account"},model:{value:(_vm.filterUser),callback:function ($$v) {_vm.filterUser=$$v},expression:"filterUser"}}),_c('date-text-input',{attrs:{"dense":"","label":_vm.$t('transactions.transactionsSince'),"input-class":"pa-0 ml-4"},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}})],1)]},proxy:true}:null,{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('transaction-type-chip',{attrs:{"transaction-type":item.type}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localeNumber")(item.amount,_vm.$i18n.locale))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readablePrice")(item.price,_vm.$i18n.locale))+" ")]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.dateCreated,true))+" ")]}},{key:"item.datePerformed",fn:function(ref){
var item = ref.item;
return [(_vm.compact)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.datePerformed))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.datePerformed,true))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm._f("readableIsoDate")(item.datePerformed,true))+" ")])]}},{key:"item.buyerFullName",fn:function(ref){
var item = ref.item;
return [(item.buyerId)?_c('div',[_c('icon-buy-sell',{attrs:{"type":"buy"}}),(_vm.withActions)?_c('a',{staticClass:"text-decoration-none",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.filterUser = item.buyerId}}},[_vm._v(" "+_vm._s(item.buyerFullName)+" ")]):_c('span',[_vm._v(_vm._s(item.buyerFullName))])],1):_c('div',[_vm._v(" - ")])]}},{key:"item.sellerFullName",fn:function(ref){
var item = ref.item;
return [(item.sellerId)?_c('div',[_c('icon-buy-sell',{attrs:{"type":"sell"}}),(_vm.withActions)?_c('a',{staticClass:"text-decoration-none",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.filterUser = item.sellerId}}},[_vm._v(" "+_vm._s(item.sellerFullName)+" ")]):_c('span',[_vm._v(_vm._s(item.sellerFullName))])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.hasDocument)?_c('table-button',{attrs:{"icon":"mdi-file-download","tooltip":_vm.$t('common.download'),"href":_vm.getDownloadLink(item.id),"target":"_blank"}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }